<template>

  <div class="con-wrap">
    <CarrotTitle title="현장경영 스케줄">
      <div class="btn-ibbox title-btnbox ml-150 mb-10">
        <router-link :to="{ name:'customerManagement-scheduleVisitStatus' }"><button class="btn-default type2">현황보기</button></router-link>
      </div>
      - <span class="txt-s1">검정색글씨</span> : 외근 예정자 / <span class="txt-s2">하늘색글씨</span> : 외근일지 작성자 / <span class="txt-s3">주황색글씨</span> : 외근일지 미작성자
    </CarrotTitle>



    <div class="board">
      <div class="mt-40">
        <div>

          <div class="day-select">
            <button class="btn btn-prev" @click="cal.movePrev">prev</button>
            <button class="day btn-cal">{{ cal.ymd }}</button>
            <button class="btn btn-next" @click="cal.moveNext">next</button>
          </div>
          <div class="mt-40 mb-20">
            <select v-model="cal.state" class="w-200px mr-5" @change="cal.doSearch">
              <option value="">스케줄 전체</option>
              <option value="MY">나의 스케줄</option>
            </select>
            <CarrotDept v-if="cal.isShow" @change="cal.doSearch" v-model="cal.office" class="w-200px"></CarrotDept>
            <CarrotTeam v-if="cal.isShow" @change="cal.doSearch" :idx_office="cal.office" v-model="cal.team" class="w-200px ml-5"></CarrotTeam>
            <div class="clear"></div>
          </div>
          <div class="dp-table w-100per mt-40 mb-20">
            <button @click="cal.showWeeks" class="btn-default float-left w-70px mr-10">주별</button>
            <button @click="cal.showMonths" class="btn-default float-left w-70px">월별</button>
            <button @click="cal.addSchedule" class="btn-default float-right">스케줄 등록</button>
          </div>
          <CarrotCalendar v-if="cal.isShow" :ymd="cal.ymd" :daysinfo="cal.dates" @selected="cal.doSelect"></CarrotCalendar>

        </div>
      </div>
    </div>
  </div>

</template>

<script>
// @ is an alias to /src
import { onMounted, reactive } from 'vue'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
import CarrotCalendar from '@/components/common/CarrotCalendar.vue'
import {useRouter} from "vue-router";
import CarrotDept from '@/components/common/CarrotDept.vue'
import CarrotTeam from '@/components/common/CarrotTeam.vue'

export default {
  layout:"myPIMS",
  components: {
    CarrotCalendar,
    CarrotDept,
    CarrotTeam
  },
  setup() {
    const toast = useToast();
    const router = new useRouter();
    const cal = reactive({
      isMounted:false,
      ymd  : "",
      page : 1,
      rows : 1000,

      list : [], total : 0,
      holiday:[],
      dates : [],
      state   : "",             // schedule type
      office  : "0",            // hq office
      team    : "0",            // hq team
      doSearch: () => {
        let params = {
          ymd  : cal.ymd,
          page : cal.page,
          rows : cal.rows,
          state  : cal.state,
          office : cal.office,
          team   : cal.team
        };

        cal.dates = [];
        cal.isShow = false;

        axios.get('/rest/customermgr/getScheduleDatesAll', { params : params }).then((res) => {
          if( res.data.err == 0 ){
            cal.list  = res.data.list;
            cal.holiday  = res.data.holiday;
            cal.total = res.data.total;

            for(let i=0; i<cal.list.length; i++){
              let irow = cal.list[i];
              let jrow = cal.list[i].times;
              for(let j=0; j<jrow.length; j++){
                let nitm = { idx: irow.idx };
                nitm.cdate = irow.cdate;
                nitm.text = jrow[j].hq_ename + ':' +jrow[j].company_kname ;
                nitm.class = jrow[j].state;
                nitm.type = "schedule";
                nitm.idx = jrow[j].idx;
                nitm.sub_text1 = "매니저: "+jrow[j].hq_ename + '(' + jrow[j].hq_kname +')';
                nitm.sub_text2 = "방문일시 : "+jrow[j].vdate +jrow[j].vtime;
                nitm.sub_text3 = "고객사 : "+jrow[j].company_kname;
                cal.dates.push(nitm);
              }
            }

          } else {
            if(res.data.err_msg) toast.error(res.data.err_msg);
          }
          cal.isShow = true;
        })
      },

      selected: (node) => {
        console.info(node);
      },

      movePrev: ()=>{
        let cur = cal.ymd.split("-");
        let td = new Date(cur[0], (Number(cur[1])-1), 0);

        cal.ymd = td.getFullYear() + '-' + (td.getMonth()>8?td.getMonth()+1:'0'+(td.getMonth()+1));

        cal.doSearch();
      },

      moveNext: () => {
        let cur = cal.ymd.split("-");
        let td = new Date(cur[0], (Number(cur[1])+1), 0);

        cal.ymd = td.getFullYear() + '-' + (td.getMonth()>8?td.getMonth()+1:'0'+(td.getMonth()+1));

        cal.doSearch();
      },

      showMonths : () => {
        sessionStorage.setItem('scheduleVisitMonthly', JSON.stringify({
          sdate  : cal.sdate,
          edate  : cal.edate,
          state  : cal.state,
          office : cal.office,
          team   : cal.team,
          ymd : cal.ymd
        }));
        router.push({
          name : 'customerManagement-scheduleVisitMonthly'
        });
      },
      showWeeks : () => {
        sessionStorage.setItem('scheduleVisitMonthly', JSON.stringify({
          sdate  : cal.sdate,
          edate  : cal.edate,
          state  : cal.state,
          office : cal.office,
          team   : cal.team,
          ymd : cal.ymd
        }));
        router.push({
          name : 'customerManagement-scheduleVisitWeekly'
        });
      },
      addSchedule : () => {
        sessionStorage.setItem('scheduleVisitMonthly', JSON.stringify({
          sdate  : cal.sdate,
          edate  : cal.edate,
          state  : cal.state,
          office : cal.office,
          team   : cal.team,
          ymd : cal.ymd
        }));

        router.push({
          name:'customerManagement-scheduleVisitAdd'
        });
      },

      doSelect: (o) => {
        if( Number(o.idx) > 0 ){
          cal.showSchedule(o.idx);
        }
      },

      showSchedule : (idx) => {
        sessionStorage.setItem('scheduleVisitMonthly', JSON.stringify({
          sdate  : cal.sdate,
          edate  : cal.edate,
          state  : cal.state,
          office : cal.office,
          team   : cal.team,
          ymd : cal.ymd
        }));

        router.push({
          name:'customerManagement-scheduleVisitView-idx',
          params:{
            idx:idx
          }
        });
      },

    });


    onMounted(() => {
      // Mounted
      console.log(cal.ymd);
      let td = new Date();
      cal.ymd = td.getFullYear() + '-' + (td.getMonth()>8?td.getMonth()+1:'0'+(td.getMonth()+1));
      console.log(cal.ymd);


      let ss = sessionStorage.getItem("scheduleVisitMonthly");
      if( ss ){
        ss = JSON.parse(ss);
        cal.page  = ss.page?ss.page:1;
        if( ss.sdate ) cal.sdate = ss.sdate;
        if( ss.edate ) cal.edate = ss.edate;
        cal.state  = ss.state?ss.state:"";
        cal.office = ss.office>0?ss.office:0;
        cal.team   = ss.team>0?ss.team:0;
        if( ss.ymd ) cal.ymd = ss.ymd;
      }


      cal.doSearch();
    });

    return {cal};
  }
}
</script>

<style lang="scss" scoped>
</style>